<template lang="pug">
  v-app
    v-main
      v-container(align="start" justify="start" fluid)
        router-view
      notifications(position="bottom center")
</template>
<script>
export default {
};
</script>
