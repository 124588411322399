<template lang="pug">
  v-row(v-if="filteredLabels.length")
    v-col(v-for="label in filteredLabels" :key="label.label" sm="12" md="6" lg="4" xl="3")
      v-card(:color="label.color" :to="label.route" dark height="100%" class="text-center" flat)
        v-card-text(class="pa-5")
          v-icon(size="75") {{ label.icon }}
          h3(class="text-uppercase mt-5") {{ label.label }}
</template>
<script>
export default {
  props: {
    labels: {
      type: Array,
      required: true
    }
  },
  computed: {
    filteredLabels () {
      return this.labels.filter(label => label.depth === 0);
    }
  }
};
</script>
