<template lang="pug">
  v-app
    v-main
      v-navigation-drawer(v-model="drawer" absolute temporary)
        site-sidebar(:labels="labels")
      v-card(flat)
        v-img(
          class="white--text align-end"
          height="250px"
          :src="require('@/assets/images/site_bg.jpg')"
        )
          v-card-title(v-if="institution" class="pb-0 justify-center justify-md-start text-h4 text-sm-h3 text-uppercase") {{ institution.name }}
          v-card-title(class="justify-center justify-md-start text-h5 text-sm-h4") Monitorul Oficial Local
      v-container(fluid)
        v-row
          v-col(cols="12" md="4" xl="3" class="d-none d-md-flex")
            site-sidebar(:labels="labels")
          v-col(cols="12" md="8" xl="9")
            v-btn(block dark @click.stop="drawer = !drawer" class="blue-grey d-block d-md-none mb-4")
              v-icon(left) menu
              span Meniu
            router-view(v-if="institution && labels" :institution="institution" :labels="labels")
      notifications(position="bottom center")
      page-loading(
        loader="dots"
        color="#3f51b5"
        background-color="#000"
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      )
</template>
<script>
import { findLabel } from '@/components/QuickAccess';
import PageLoading from 'vue-loading-overlay';
import { mapGetters } from 'vuex';
import 'vue-loading-overlay/dist/vue-loading.css';
import SiteSidebar from '@/views/public/site/components/SiteSidebar';

export default {
  components: {
    PageLoading,
    SiteSidebar
  },
  props: {
    institutionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      institution: null,
      labels: [],
      drawer: false
    };
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  mounted () {
    this.$store.commit('pageLoading', true);

    Promise.all([
      this.loadInstitution(),
      this.loadLabels()
    ])
      .catch(e => {
        this.$error(e);
      })
      .finally(() => {
        this.$store.commit('pageLoading', false);
      });
  },
  methods: {
    loadInstitution () {
      return this.axios.get(`/institutions/${this.institutionId}`)
        .then(res => {
          this.institution = res.data.data;
        });
    },
    getFormattedLabels (labels) {
      return labels.map(label => ({
        label: label.name,
        depth: label.depth,
        parent: label.parent,
        children: this.getFormattedLabels(label.children),
        route: { name: 'public.site.label', params: { labelId: label.id } },
        ...findLabel(label.name)
      }));
    },
    loadLabels () {
      const params = {
        filters: {
          only_with_media: false,
          format: 'tree'
        }
      };
      return this.axios.get(`/institutions/${this.institutionId}/labels`, { params })
        .then(res => {
          this.labels = this.getFormattedLabels(res.data.data);
        });
    }
  }
};
</script>
