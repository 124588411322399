<template lang="pug">
  div
    v-card-title
      div(class="flex-grow-1")
      v-text-field(
        v-model="search"
        append-icon="search"
        label="Cauta..."
        single-line
        hide-details
      )
    v-data-table(
      :headers="headers"
      :items="files"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [5, 20, 25, 50, 100] }"
      class="elevation-1"
      disable-sort
    )
      template(#item="{ item }")
        tr
          td
            a(:href="item.url" style="text-decoration:none" title="Descarca" target="_black")
              v-icon(left small) cloud_download
              span {{ item.name }}
          td(class="text-no-wrap") {{ item.size }}
          td(class="text-no-wrap") {{ item.addedDate }}
</template>
<script>
import _ from 'lodash';
export default {
  props: {
    institutionId: {
      type: Number,
      required: true
    },
    labelId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loadFilesDebouncer: null,
      search: null,
      files: [],
      loading: false,
      headers: [
        { text: 'Nume', value: 'name' },
        { text: 'Marime', value: 'size' },
        { text: 'Data adaugarii', value: 'addedDate' }
      ],
      options: {
        page: 1,
        perPage: 10
      },
      total: 0
    };
  },
  watch: {
    search: function () {
      this.loadFilesDebouncer();
    },
    options: {
      handler () {
        this.loadFiles();
      },
      deep: true
    }
  },
  created () {
    this.loadFilesDebouncer = _.debounce(this.loadFiles, 500);
  },
  methods: {
    loadFiles () {
      this.loading = true;

      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search
      };

      return this.axios.get(`institutions/${this.institutionId}/labels/${this.labelId}/files`, { params })
        .then(res => {
          this.files = res.data.data;
          this.total = res.data.meta.pagination.total;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
