<template lang="pug">
  v-app(:style="{ backgroundImage }")
    v-main
      v-container(fluid fill-height)
        v-layout(align-center justify-center)
          v-flex(xs12 sm8 md6)
            validation-observer(ref="observer" @submit.prevent="sendRequest")
              v-form
                v-card(class="elevation-12")
                  v-toolbar(:color="theme.colors.primary" dark flat)
                    v-toolbar-title
                      span Autentificare
                      span(class="font-weight-bold text-capitalize pl-1") Monitorul Oficial Local
                  v-card-text
                    validation-provider(name="email" :rules="form.email.validation.rules" v-slot="{ errors }")
                      v-text-field(
                        v-model="form.email.value"
                        prepend-icon="person"
                        type="email"
                        required
                        :error-messages="errors"
                        :label="form.email.label"
                      )
                    validation-provider(name="password" :rules="form.password.validation.rules" v-slot="{ errors }")
                      v-text-field(
                        v-model="form.password.value"
                        prepend-icon="lock"
                        type="password"
                        required
                        :error-messages="errors"
                        :label="form.password.label"
                      )
                  v-card-actions
                    v-spacer
                    v-btn(color="success" type="submit")
                      span Autentifica
                      v-icon(right) login
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  data () {
    return {
      form: {
        email: {
          value: null,
          label: 'Adresa de-email',
          validation: {
            rules: 'required|email'
          }
        },
        password: {
          value: null,
          label: 'Parola',
          validation: {
            rules: 'required'
          }
        }
      },
      backgroundImage: `url(${require('@/assets/images/login_bg.jpg')})`
    };
  },
  computed: {
    ...mapState({
      theme: s => s.config.theme
    })
  },
  watch: {
    '$store.state.auth.formErrors' (errors) {
      if (errors) {
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    sendRequest () {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.$store.commit('pageLoading', true);

          return this.login({ email: this.form.email.value, password: this.form.password.value })
            .catch(e => {
              this.$error(e);
            })
            .finally(() => {
              this.$store.commit('pageLoading', false);
            });
        }
      });
    }
  }
};
</script>
<style>
.theme--light.v-application {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
