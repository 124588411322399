<template lang="pug">
  div
    v-card(class="my-2" color="blue-grey lighten-5")
      v-card-text
        v-text-field(
          v-model="search"
          append-icon="search"
          label="Cauta dupa titlu, continut..."
          class="pt-0"
          single-line
          hide-details
          clearable
        )
    v-data-table(
      :headers="headers"
      :items="files"
      :loading="loading"
      :options.sync="options"
      :server-items-length="total"
      :footer-props="{ 'items-per-page-options': [5, 20, 25, 50, 100] }"
      class="elevation-1"
      disable-sort
    )
      template(#item="{ item }")
        tr
          td {{ item.name }}
          td(class="text-no-wrap") {{ item.size }}
          td(class="text-no-wrap") {{ item.addedDate }}
          td(class="text-right")
            v-btn(
              :href="item.url"
              :title="`Descarca ${item.name}`"
              target="_black"
              class="white--text"
              color="green"
              small
            )
              span Descarca
              v-icon(right dark small) cloud_download
</template>
<script>
import _ from 'lodash';
export default {
  props: {
    institution: {
      type: Object,
      required: true
    },
    labelId: {
      required: true
    }
  },
  data () {
    return {
      loadFilesDebouncer: null,
      search: null,
      files: [],
      loading: false,
      headers: [
        { text: 'Nume', value: 'name' },
        { text: 'Marime', value: 'size' },
        { text: 'Data publicarii', value: 'addedDate' },
        { text: '' }
      ],
      options: {
        page: 1,
        perPage: 10
      },
      total: 0
    };
  },
  watch: {
    search: function () {
      this.loadFilesDebouncer();
    },
    options: {
      handler () {
        this.loadFiles();
      },
      deep: true
    },
    labelId: {
      handler () {
        this.loadFiles();
      }
    }
  },
  created () {
    this.loadFilesDebouncer = _.debounce(this.loadFiles, 500);
  },
  methods: {
    paddingStyle (depth) {
      return depth ? `padding:12px 16px 12px ${depth * 16}px` : '';
    },
    loadFiles () {
      this.loading = true;

      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search
      };

      return this.axios.get(`institutions/${this.institution.id}/labels/${this.labelId}/files`, { params })
        .then(res => {
          this.files = res.data.data;
          this.total = res.data.meta.pagination.total;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.label-select .v-select__selections {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
