<template lang="pug">
  div
    v-progress-linear(v-if="loading" indeterminate color="indigo")
    router-view(v-else-if="labels.length" :labels="labels" :institutionId="institutionId")
    v-alert(v-else type="info") Nu există date disponibile
</template>
<script>
export default {
  props: {
    institutionId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      labels: []
    };
  },
  mounted () {
    this.loadLabels();
  },
  methods: {
    loadLabels () {
      this.loading = true;

      return this.axios.get(`/institutions/${this.institutionId}/labels`)
        .then(res => {
          this.labels = res.data.data;
        })
        .catch(e => {
          this.$error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style>
  .theme--light.v-application {
    background-color: transparent;
  }
</style>
