<template lang="pug">
  v-app
    v-main
      v-container(align="start" justify="start" fluid class="pa-0")
        router-view
      notifications(position="bottom center")
      page-loading(
        loader="dots"
        color="#3f51b5"
        background-color="#000"
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
      )
</template>
<script>
import { mapGetters } from 'vuex';
import PageLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    PageLoading
  },
  computed: {
    ...mapGetters(['isLoading'])
  }
};
</script>
