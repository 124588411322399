<template lang="pug">
  v-list-group(v-if="label.children.length" :color="label.color" @click="goToRoute" :value="isGroupActive")
    template(v-slot:activator)
      v-list-item-icon
        v-icon(v-if="label.depth === 0") {{ label.icon }}
      v-list-item-content
        v-list-item-title(:style="`white-space:normal;${paddingStyle}`") {{ label.label }}
    template(v-for="child in label.children")
      site-sidebar-item(:label="child")
  v-list-item(v-else link :to="label.route" :color="label.color")
    v-list-item-icon
      v-icon(v-if="label.depth === 0") {{ label.icon }}
    v-list-item-content
      v-list-item-title(:style="`white-space:normal;${paddingStyle}`") {{ label.label }}
</template>
<script>
export default {
  name: 'SiteSidebarItem',
  props: {
    label: {
      type: Object,
      required: true
    }
  },
  computed: {
    isGroupActive () {
      if (parseInt(this.$route.params.labelId) === this.label.route.params.labelId) {
        return true;
      }

      return this.label.children.some(child => child.route.params.labelId === this.$route.params.labelId);
    },
    groupRoute () {
      if (parseInt(this.$route.params.labelId) !== this.label.route.params.labelId) {
        return this.label.route;
      } else if (this.label.children.some(child => child.route.params.labelId === this.$route.params.labelId)) {
        return { name: 'public.site.index' };
      } else {
        return null;
      }
    }
  },
  methods: {
    paddingStyle () {
      return this.label.depth ? `padding:12px 16px 12px ${this.label.depth * 18}px` : '';
    },
    goToRoute () {
      if (this.groupRoute) {
        this.$router.push(this.groupRoute);
      }
    }
  }
};
</script>
