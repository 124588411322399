<template lang="pug">
  v-list(width="100%")
    v-list-item(link :to="{ name: 'public.site.index' }" exact)
      v-list-item-icon
        v-icon home
      v-list-item-content
        v-list-item-title Pagina principala
    template(v-for="label in labels")
      site-sidebar-item(:label="label")
</template>
<script>
import SiteSidebarItem from './SiteSidebarItem';
export default {
  components: {
    SiteSidebarItem
  },
  props: {
    labels: {
      type: Array,
      required: true
    }
  }
};
</script>
